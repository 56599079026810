import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Kreditregeln = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Neue Kreditregeln ab August 2022 – Was ändert sich?" showCalc={false} />
            <Article>
                <p>
                    Du bist gerade dabei, die Finanzierung für dein neues Zuhause zu planen, stößt dabei immer wieder
                    auf Schlagzeilen zu veränderten Kreditregeln, hast aber weder Zeit noch Lust, dich in
                    unterschiedlichen Medien darüber zu informieren? Sei ganz relaxed, wir haben hier das Wichtigste für
                    dich zusammengefasst. So kannst du dich bald wieder auf das Wesentliche konzentrieren: die Suche
                    nach deiner Traumimmobilie.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Warum werden die Kreditregeln angepasst?</h2>
                <p>
                    Wie du sicher schon bemerkt hast, steigen die Immobilienpreise auch auf dem österreichischen
                    Immobilienmarkt seit mehreren Jahren rasant. Gleichzeitig befinden sich die Zinsen ebenfalls seit
                    längerem auf einem historisch niedrigen Niveau. Um einer Überhitzung des Immobilienmarktes
                    vorzubeugen, die langfristig die gesamte Volkswirtschaft gefährden könnte, haben bereits mehrere
                    europäische Länder ihre Kreditregeln angepasst. Auch hierzulande gab es bereits einige Zeit eine
                    Empfehlung der Nationalbank dies zu tun. Nun wird genau das, auf Anraten der EZB (Europäische
                    Nationalbank), auch in Österreich zur Pflicht. Ab August 2022 treten neue Kreditregeln in Kraft.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche neuen Kreditregeln kommen?</h2>
                <p>
                    Wenn du dich schon ein wenig mit Krediten beschäftigt hast, dann wirst du wissen, dass eines der
                    wichtigsten Kriterien bei ihrer Vergabe das Eigenkapital der zukünftigen Schuldner:in ist. Die
                    meisten Kreditinstitute vergeben eine Finanzierung nur dann, wenn du über eine Summe von zumindest
                    20 % des Kaufpreises verfügst. Bislang gab es allerdings Alternativen: Wer ein überdurchschnittlich
                    hohes Einkommen oder anderweitige Sicherheiten nachwies, kam mitunter auch ohne diesen Betrag an
                    einen Kredit. Genau das ändert sich jetzt. Die neuen Kreditregeln sehen vor, dass Finanzierungen zum
                    Kauf von Immobilien ausschließlich dann zu vergeben sind, wenn die potentielle Kreditnehmer:in
                    Eigenkapital in der Höhe von 20 % des Kaufpreises besitzt – inkl. der Nebenkosten, versteht sich.
                    Hinzu kommt, dass die monatliche Kreditrate mit Inkrafttreten der neuen Regularien höchstens 40 %
                    deines Nettoeinkommen betragen darf. Auch das soll breite Zahlungsausfälle verhindern. Die letzte
                    Änderung betrifft die Laufzeit von Immobilienkrediten: Diese darf höchstens 35 Jahre betragen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die neuen Kreditregeln im Überblick</h2>
                <p>Ab August 2022 sind bei der Vergabe von Immobilienkrediten folgende Richtlinien verbindlich:</p>
                <ul>
                    <li>
                        Kreditnehmer:innen müssen mindestens 20 % des Kaufpreises (inkl. Nebenkosten) in Form von
                        Eigenkapital nachweisen
                    </li>
                    <li>Die monatliche Rate darf nicht mehr als 40 % des Nettoeinkommens betragen</li>
                    <li>Die Laufzeit von Immobilienkrediten wird auf höchstens 35 Jahre beschränkt</li>
                </ul>
                <p>
                    Psst, ein kleiner Tipp: Wenn du dich eigenständig über die voraussichtlichen Konditionen deiner
                    zukünftigen Finanzierung informieren willst, nutze am besten unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <hr />

                <h2>Was bedeuten die neuen Kreditregeln für mich als Käufer:in?</h2>
                <p>
                    Noch sind die neuen Kreditregeln nicht gültig. Sollte durch eines der genannten Kriterien deinem
                    Kreditantrag ein Stein in den Weg gelegt werden, dann kannst du versuchen, noch vor ihrem
                    Inkrafttreten an eine Finanzierung zu gelangen. Dabei ist allerdings Vorsicht geboten. Hinter den
                    neuen Kreditregeln stehen fundamentale ökonomische Überlegungen des Europäischen Rates für
                    Systemrisiken sowie der Europäischen Zentralbank. Kreditnehmer:innen, die den skizzierten Vorgaben
                    nicht entsprechen, weisen ein erhöhtes Risiko des Zahlungsausfalls auf. Lass dich von den Neuerungen
                    also keinesfalls unter Druck setzen! Lass dich lieber umfassend beraten!
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kostenloses Erstgespräch vereinbaren
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"kreditregeln"}></BreadcrumbList>
            <ArticleStructuredData
                page={"kreditregeln"}
                heading={"Neue Kreditregeln ab August 2022 – Was ändert sich?"}
            />
        </Layout>
    );
};

export default Kreditregeln;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kreditregeln", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
