import React from "react";

import { ContentWrapper } from "../assets/styles/base";
import MortgageCalculator from "./MortgageCalculator";

const ArticleHeader = ({ h1Title, showCalc, calcCTA, ctaLink = "" }) => {
    if (showCalc) {
        return (
            <>
                <MortgageCalculator useH1={true} h1Title={h1Title} calcCTA={calcCTA} ctaLink={ctaLink} hasMarginTop />
            </>
        );
    }
    return (
        <ContentWrapper hasMarginTop>
            <h1 className="no-margin">{h1Title}</h1>
        </ContentWrapper>
    );
};

ArticleHeader.defaultProps = {
    h1Title: "",
    showCalc: false,
};

export default ArticleHeader;
