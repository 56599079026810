import React from "react";
import styled, { css } from "styled-components";
import { ContentWrapperCss } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";

const Article = ({ children, hasMarginTop, hasColumns }) => {
    return (
        <Wrapper hasMarginTop={hasMarginTop} hasColumns={hasColumns}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.article`
    ${ContentWrapperCss};
    font-family: "Inter", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial,
        "Lucida Grande", sans-serif;
    line-height: 1.6;

    margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "50px" : "0")};
    word-break: break-word;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "100px" : "0")};
    }

    p {
        color: #111827;
        margin: 0 0 20px;

        @media (min-width: ${breakpoints.desktopMin}) {
            font-size: 17px;
            margin-bottom: 30px;
        }
    }
    hr {
        border: 1px solid #dcdcff;
        margin: 40px 0 40px -2%;
        width: 104%;
    }

    ${({ hasColumns }) =>
        hasColumns &&
        css`
            @media (min-width: ${breakpoints.tabletBigMin}) {
                column-count: 2;
                column-gap: 40px;
            }
            @media (min-width: ${breakpoints.desktopMin}) {
                column-count: 3;
            }
        `};
`;

export default Article;
