import React from "react";
import styled from "styled-components";
import { Trans } from "gatsby-plugin-react-i18next";

import { colors } from "../constants/colors";
import { breakpoints } from "../constants/breakpoints";
import { H2 } from "../assets/styles/base";

const FinancingExample = ({ handleModalClose }) => {
    return (
        <Wrapper onClick={handleModalClose}>
            <ModalWrapper onClick={(e) => e.stopPropagation()}>
                <Header>
                    <Trans>mortgageCalc.financingExample.title</Trans>
                    <CloseBtn onClick={handleModalClose}>&#x2715;</CloseBtn>
                </Header>
                <table>
                    <tbody>
                        <tr>
                            <td>Kreditbetrag</td>
                            <td>200.000 €</td>
                        </tr>
                        <tr>
                            <td>Laufzeit</td>
                            <td>35 Jahre</td>
                        </tr>
                        <tr>
                            <td>Zinssatz (fix)</td>
                            <td>3,800 %</td>
                        </tr>
                        <tr>
                            <td>Effektiver Zinssatz</td>
                            <td>4,300 %</td>
                        </tr>
                        <tr>
                            <td>Monatliche Raten</td>
                            <td>882 €</td>
                        </tr>
                        <tr>
                            <td>Zu zahlender Gesamtbetrag</td>
                            <td>370.440 €</td>
                        </tr>
                        <tr>
                            <td>Tatsächlicher Auszahlungsbetrag</td>
                            <td>192.500 €</td>
                        </tr>
                        <tr>
                            <td>Einberechnete Kosten</td>
                            <td>7.500 €</td>
                        </tr>
                    </tbody>
                </table>
                <BottomInfo>
                    <small>
                        <Trans i18nKey="mortgageCalc.bottomExampleInfo">
                            *Repräsentatives Beispiel laut Hypothekar- und Immobiliengesetz (
                            <a href="https://rdb.manz.at/document/ris.n.NOR40176276" rel="noreferrer" target="_blank">
                                HlKrG
                            </a>
                            )
                        </Trans>
                    </small>
                    <p>
                        <Trans>mortgageCalc.financingExample.bottomInfo</Trans>
                    </p>
                </BottomInfo>
            </ModalWrapper>
        </Wrapper>
    );
};

const modalPadding = "60px";

const Wrapper = styled.div`
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }
    td {
        padding: 7px 10px;
        vertical-align: top;

        :first-child {
            color: ${colors.primary};
        }
        :last-child {
            text-align: right;
            white-space: nowrap;
        }
    }

    transition: opacity ease-out 300ms;

    > div {
        opacity: 1;
        margin-bottom: ${modalPadding};
        margin-top: ${modalPadding};
        transition: margin-top ease-out 300ms;
    }
    &.enter {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
    &.enter-active {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit {
        opacity: 1;

        > div {
            margin-top: ${modalPadding};
        }
    }
    &.exit-active {
        opacity: 0;

        > div {
            margin-top: 0;
        }
    }
`;

const ModalWrapper = styled.div`
    background: #fff;
    border-radius: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    max-width: 460px;
    padding: 30px 20px;
    width: 90%;

    @media (min-width: ${breakpoints.desktopMin}) {
        max-width: 520px;
        padding: 40px 30px;
    }
`;

const BottomInfo = styled.div`
    border-top: 2px solid #dcdcff;
    margin-top: 25px;
    text-align: justify;

    small {
        display: block;
        font-size: 11px;
        padding: 7px 10px;
    }
    p {
        margin: 20px 0 0;
        font-size: 14px;
        line-height: 1.5;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
        padding-top: 12px;

        small {
            font-size: 15px;
            line-height: 1.6;
        }
    }
`;

const Header = styled(H2)`
    align-items: center;
    display: flex;
`;

const CloseBtn = styled.span`
    cursor: pointer;
    font-size: 1.1em;
    margin-left: auto;
`;

export default FinancingExample;
