import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import breadcrumbPagesWhiteList from "./BreadcrumbWhiteList";

const ArticleStructuredData = ({ page, heading = "", description = "", datePublished = "", dateModified = "" }) => {
    const { t } = useTranslation();

    if (!datePublished) {
        datePublished = t("page.article.datePublished");
    }

    if (!dateModified) {
        dateModified = t("page.article.dateModified");
    }

    if (!heading) {
        heading = t("page.meta.title");
    }

    if (!description) {
        description = t("page.meta.description");
    }

    let articleUrl = process.env.GATSBY_SITE_URL;
    if (breadcrumbPagesWhiteList[page]) {
        const { path } = breadcrumbPagesWhiteList[page];
        if (path[1].url) {
            articleUrl = process.env.GATSBY_SITE_URL + path[1].url;
        }
    }
    /* Pre-define the FAQ structured-data schema, which is used to generate the JSON-LD */
    const articleStructuredDataSchema = {
        "@context": "https://schema.org",
        "@type": "Article",
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": articleUrl,
        },
        headline: heading,
        description: description,
        image: process.env.GATSBY_SITE_URL + "/og-image-miracl.png",
        author: {
            "@type": "Organization",
            name: "miracl",
            url: process.env.GATSBY_SITE_URL,
        },
        publisher: {
            "@type": "Organization",
            name: "miracl",
            logo: {
                "@type": "ImageObject",
                url: process.env.GATSBY_SITE_URL + "/og-image-miracl.png",
            },
        },
        datePublished: datePublished,
        dateModified: dateModified,
    };

    return <Helmet>{<script type="application/ld+json">{JSON.stringify(articleStructuredDataSchema)}</script>}</Helmet>;
};

export default ArticleStructuredData;
