import { Trans } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { CSSTransition } from "react-transition-group";
import styled, { css } from "styled-components";

import { Button, CenterBlock, ContentWrapper, TextInputCss } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";
import { colors } from "../constants/colors";
import { FIX_RATE, VARIABLE_RATE } from "../constants/rates";
import { extendUrlWithSourceVersion, urls } from "../constants/urls";
import GtmService from "../service/gtmService";
import FinancingExample from "./FinancingExample";

const Title = ({ useH1, h1Title, calcCTA }) => {
    if (useH1) return <h1 className="h1">{h1Title ? h1Title : <Trans>mortgageCalc.title</Trans>}</h1>;
    return (
        <h2 className="h1">
            <Trans>mortgageCalc.title</Trans>
        </h2>
    );
};

const monthlyToPay = (mortgage, rate, term) => {
    let creditSum = -mortgage;
    let rateNum = parseFloat(rate.replace(",", "."));
    let preRate = (rateNum / 1200 / 360) * 365;
    let prePeriod = term * 12;

    return (
        (preRate * creditSum * Math.pow(1 + preRate, prePeriod)) /
        (1 - Math.pow(1 + preRate, prePeriod))
    ).toFixed();
};

const MortgageCalculator = ({
    useH1,
    h1Title,
    calcCTA,
    ctaLink = "",
    defaultTerm,
    defaultMortgage,
    hasGreyBg,
    hasMarginTop,
}) => {
    const urlParams = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : false;
    const priceQueryParam = urlParams ? parseInt(urlParams.get("kaufpreis"), 10) : false;
    const [mortgage, setMortgage] = useState(defaultMortgage);

    useEffect(() => {
        if (priceQueryParam && mortgage !== priceQueryParam) {
            // update price from query (SSR fix)
            setMortgage(priceQueryParam);
        }
    }, [mortgage, priceQueryParam]);

    const [type, setType] = useState("fix");
    const [term, setTerm] = useState(defaultTerm);
    const [showFinancingModal, setShowFinancingModal] = useState(false);
    const termOptions = [5, 10, 15, 20, 25, 30, 35, 40];
    const rate = type === "fix" ? FIX_RATE : VARIABLE_RATE;

    const handleModalOpen = () => {
        document.body.classList.add("modal-open");

        setShowFinancingModal(true);

        GtmService.instance.trackEvent({
            event: "kreditRechnerFinanzierungBeispielClick",
        });
    };
    const handleModalClose = () => {
        document.body.classList.remove("modal-open");
        setShowFinancingModal(false);
    };

    const Content = (
        <StyledContentWrapper hasMarginTop={hasMarginTop}>
            <ColRight>
                <Title useH1={useH1} h1Title={h1Title} />
                <div className="hideIfSingle">
                    <Label className="color-primary">
                        <Trans>mortgageCalc.rateLabel</Trans>:
                    </Label>
                    <Result>{rate} %</Result>

                    <Label className="color-primary">
                        <Trans>mortgageCalc.monthlyLabel</Trans>:
                    </Label>

                    <Result>{monthlyToPay(mortgage, rate, term)} €</Result>

                    <p>
                        {ctaLink !== "" ? (
                            <a href={extendUrlWithSourceVersion(`${ctaLink}`)}>
                                <Button>{calcCTA ? calcCTA : <Trans>mortgageCalc.calculateBtn</Trans>}</Button>
                            </a>
                        ) : (
                            <a
                                href={extendUrlWithSourceVersion(
                                    `${urls.survey}?amount=${mortgage}&term=${term}&interestRateType=${type}&component=kreditrechner`,
                                )}
                            >
                                <Button>{calcCTA ? calcCTA : <Trans>mortgageCalc.calculateBtn</Trans>}</Button>
                            </a>
                        )}
                    </p>
                </div>
            </ColRight>

            <ColLeft>
                <h3 className="h2 hideIfSingle">Kreditrechner</h3>
                <TypeWrapper>
                    <TypeBtn
                        isActive={type === "fix" ? true : false}
                        onClick={() => {
                            setType("fix");

                            GtmService.instance.trackEvent({
                                event: "kreditRechnerFixClick",
                            });
                        }}
                    >
                        Fix
                    </TypeBtn>
                    <TypeBtn
                        isActive={type === "variable" ? true : false}
                        onClick={() => {
                            setType("variable");

                            GtmService.instance.trackEvent({
                                event: "kreditRechnerVariableClick",
                            });
                        }}
                    >
                        Variabel
                    </TypeBtn>
                </TypeWrapper>

                <GroupsWrapper>
                    <Group>
                        <Label htmlFor="mortgageLoan">
                            <Trans>mortgageCalc.mortgageLabel</Trans>:
                        </Label>
                        <StyledNumberFormat
                            allowNegative={false}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            suffix={" €"}
                            onFocus={() =>
                                GtmService.instance.trackEvent({
                                    event: "kreditRechnerKaufpreisInput",
                                })
                            }
                            onValueChange={(value) => setMortgage(value.floatValue >= 0 ? value.floatValue : 0)}
                            value={mortgage}
                            center="true"
                            color={colors.primary}
                            id="mortgageLoan"
                            pattern="[0-9]*"
                            displayType={"number"}
                        />
                        <Label className="hideIfSplit">
                            <Trans>mortgageCalc.rateLabel</Trans>:
                        </Label>
                        <Result className="hideIfSplit">{rate} %</Result>
                    </Group>
                    <Group>
                        <Label htmlFor="mortgageTerm">
                            <Trans>mortgageCalc.termLabel</Trans>:
                        </Label>
                        <TermSelectMobile
                            center
                            id="mortgageTerm"
                            onChange={(e) => setTerm(e.target.value)}
                            onClick={() =>
                                GtmService.instance.trackEvent({
                                    event: "kreditRechnerLaufzeitClick",
                                })
                            }
                            value={term}
                        >
                            {termOptions.map((option, key) => (
                                <option key={key} value={option}>
                                    {option} Jahre
                                </option>
                            ))}
                        </TermSelectMobile>
                        <Label className="hideIfSplit">
                            <Trans>mortgageCalc.monthlyLabel</Trans>:
                        </Label>
                        <Result className="hideIfSplit">{monthlyToPay(mortgage, rate, term)} €</Result>
                    </Group>
                </GroupsWrapper>

                <BottomInfo>
                    <span className="link" onClick={handleModalOpen} role="presentation">
                        &gt; Finanzierungsbeispiel
                    </span>
                </BottomInfo>

                <CenterBlock>
                    {ctaLink !== "" ? (
                        <a className="hideIfSplit" href={extendUrlWithSourceVersion(`${ctaLink}`)}>
                            <Button>{calcCTA ? calcCTA : <Trans>mortgageCalc.calculateBtn</Trans>}</Button>
                        </a>
                    ) : (
                        <a
                            className="hideIfSplit"
                            href={extendUrlWithSourceVersion(
                                `${urls.survey}?amount=${mortgage}&term=${term}&interestRateType=${type}&component=kreditrechner`,
                            )}
                        >
                            <Button>{calcCTA ? calcCTA : <Trans>mortgageCalc.calculateBtn</Trans>}</Button>
                        </a>
                    )}
                </CenterBlock>
            </ColLeft>

            <CSSTransition in={showFinancingModal} timeout={1300} unmountOnExit>
                <FinancingExample handleModalClose={handleModalClose} />
            </CSSTransition>
        </StyledContentWrapper>
    );
    if (hasGreyBg) return <GreyBg>{Content}</GreyBg>;
    return Content;
};

const media1col = breakpoints.tabletMax;
const media2col = breakpoints.desktopMin;

const GreyBg = styled.div`
    background: #f8f8f8;
`;

const StyledContentWrapper = styled(ContentWrapper)`
    @media (max-width: ${media1col}) {
        .hideIfSingle {
            display: none;
        }
    }
    @media (min-width: ${media2col}) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .hideIfSplit {
            display: none;
        }
    }
`;

const ColLeft = styled.div`
    @media (min-width: ${media2col}) {
        border: 3px solid #f6f8f7;
        border-radius: 50px;
        box-shadow: 0px 0px 30px #0000001a;
        box-sizing: border-box;
        padding: 60px;
        width: 42%;
    }
    @media (min-width: ${breakpoints.desktopBigMin}) {
        padding-left: 80px;
        padding-right: 80px;
    }
`;

const ColRight = styled.div`
    @media (min-width: ${media2col}) {
        padding-top: 50px;
        width: 52%;

        .h1,
        h2 {
            margin-bottom: 12px;
        }
        p {
            margin: 30px 0 0;
        }
    }
`;

const TypeWrapper = styled.div`
    background: #dbdbfe;
    border-radius: 18px;
    color: #fff;
    display: flex;
    height: 37px;
    margin: 40px 0 0;
`;

const TypeBtn = styled.span`
    align-items: center;
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 50%;

    ${({ isActive }) =>
        isActive &&
        css`
            background: ${colors.primary};
        `};
`;

const GroupsWrapper = styled.div`
    @media (max-width: ${media1col}) {
        display: flex;
    }
`;

const Group = styled.div`
    @media (max-width: ${media1col}) {
        width: 50%;

        &:first-child {
            margin-right: 10%;
        }
    }
`;

const Label = styled.label`
    display: block;
    margin: 35px 0 5px;
    position: relative;

    @media (max-width: ${media1col}) {
        padding-left: 20px;
    }
    @media (min-width: ${media2col}) {
        &.color-primary {
            margin-bottom: 0;
        }
    }
`;

const StyledNumberFormat = styled(NumberFormat)`
    ${TextInputCss};

    @media (min-width: ${media2col}) {
        border-radius: 23px;
        height: 46px;
    }
`;

const Result = styled.div`
    color: ${colors.primary};
    font-size: 26px;
    font-weight: 500;

    @media (max-width: ${media1col}) {
        padding-left: 20px;
    }
    @media (min-width: ${media2col}) {
        font-size: 36px;
        margin-bottom: 15px;
    }
`;

const TermSelectMobile = styled.select`
    ${TextInputCss};

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: #fff;
    color: ${colors.primary};

    /* start fix safari not centering select */
    padding-right: 0;
    padding-left: calc(50% - 37px);
    text-align: left;
    /* end safari fix */

    @media (min-width: ${media2col}) {
        border-radius: 23px;
        height: 46px;
    }
`;

const BottomInfo = styled.div`
    border-top: 2px solid #dcdcff;
    font-size: 11px;
    margin: 25px 0 25px;
    padding: 7px 5px;

    @media (min-width: ${media2col}) {
        font-size: 15px;
        line-height: 1.6;
        padding-top: 12px;
    }
`;

MortgageCalculator.defaultProps = {
    useH1: false,
    defaultTerm: 35,
    defaultMortgage: 320000,
    h1Title: "",
    hasGreyBg: false,
};

export default MortgageCalculator;
